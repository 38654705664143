import classNames from "classnames"
import React, { useContext, useRef } from "react"

import { Color } from "../../../../constants/V2/color"
import DownloadAppButton from "../../../elements/V2/Buttons/DownloadAppButton"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import Callout from "../../../elements/V2/Callout"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Section from "../../../elements/V2/Section"
import StickyContainer from "../../../elements/V2/StickyContainer"
import Typography from "../../../elements/V2/Typography"
import Video from "../../../elements/V2/Video"
import { NavigationV2Context } from "../../Navigation/context"

import { useElementObserver } from "@hooks/V2/useElementObserver"
import {
  getBackgroundColorClass,
  getAccentColor,
  getGradientFromColorClass,
  getGradientToColorClass,
} from "@utils/V2/color"
import { useIsBreakpoint } from "@utils/V2/screen"

type Props = {
  headingText: string
  headingSize?: "display1" | "h1" | "h2" | "h3" | "h4" | "h5"
  headingMobileAlignment?: "left" | "center"
  bodyText: string
  superscriptText?: string
  primaryBackgroundColor: Color
  secondaryBackgroundColor?: Color
  primaryButtonText?: string
  primaryButtonLink?: string
  primaryButtonMobileLink?: string
  primaryButtonBorderColor: Color
  secondaryButtonText: string
  secondaryCTATextColor: Color.White | Color.Charcoal
  secondaryButtonLink: string
  videoUrl?: string
  imageUrl: string
  imageAlt: string
  callout?: Storyblok.Callout
  showDownloadAppButton: boolean
  digitalPaymentsText?: string
  hasScrollArrow?: boolean
} & Partial<Storyblok.CTATrackingEvent>

const HalfHero = ({
  headingText,
  headingSize = "display1",
  headingMobileAlignment = "left",
  bodyText,
  superscriptText,
  primaryBackgroundColor,
  secondaryBackgroundColor,
  primaryButtonText,
  primaryButtonLink,
  primaryButtonMobileLink,
  primaryButtonBorderColor,
  secondaryButtonText,
  secondaryCTATextColor = Color.Charcoal,
  secondaryButtonLink,
  videoUrl,
  imageUrl,
  imageAlt,
  callout,
  showDownloadAppButton = false,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  digitalPaymentsText,
  hasScrollArrow = false,
  ...props
}: Props) => {
  const navigationContext = useContext(NavigationV2Context)
  const textContainerRef = useRef(null)
  const { isVisible: isCtaVisible, startObserving } =
    useElementObserver(textContainerRef)
  const accentColor = getAccentColor(primaryBackgroundColor)
  const isMobile = useIsBreakpoint("mobile")

  if (isMobile) {
    startObserving()
  }

  return (
    <div
      className={classNames(
        "-mb-lg pb-lg grid items-center sm:grid-cols-2",
        !secondaryBackgroundColor
          ? getBackgroundColorClass(primaryBackgroundColor)
          : [
              "bg-gradient-to-br",
              getGradientFromColorClass(primaryBackgroundColor),
              getGradientToColorClass(secondaryBackgroundColor),
            ]
      )}
      {...props}
    >
      <Section className="flex flex-col pb-32 pt-72 sm:pb-72">
        <div
          className={classNames(
            "flex flex-col sm:items-start sm:text-left",
            headingMobileAlignment === "left"
              ? "items-start"
              : "items-center text-center"
          )}
        >
          {superscriptText && (
            <Typography
              text={superscriptText}
              font="grotesk"
              weight="book"
              size="subscript-lg"
              color={accentColor}
              className="mb-8"
            />
          )}
          {callout && (
            <Callout
              color={getAccentColor(primaryBackgroundColor)}
              classname="mt-8 mb-16"
              text={callout.text}
              highlightedWord={callout.highlightedWord}
            />
          )}
          <div className="flex flex-col gap-24">
            <Typography
              text={headingText}
              font="grotesk"
              weight="medium"
              size={headingSize}
              color={accentColor}
              element="h1"
            />
            <Typography
              text={bodyText}
              font="grotesk"
              weight="book"
              size="body-lg"
              color={accentColor}
            />
          </div>
        </div>

        <div
          ref={textContainerRef}
          className="mt-20 flex flex-col items-center gap-24 sm:mt-40 sm:flex-row sm:items-baseline"
        >
          {showDownloadAppButton ? (
            <DownloadAppButton
              text={primaryButtonText || "Download App"}
              color={primaryButtonBorderColor}
            />
          ) : primaryButtonText && primaryButtonLink ? (
            <PillButton
              size="large"
              style="solid"
              text={primaryButtonText}
              color={primaryButtonBorderColor}
              linkUrl={primaryButtonLink}
              mobileLinkUrl={primaryButtonMobileLink}
              trackingEvent={trackingEvent}
              trackingEventKey={trackingEventKey}
              trackingEventValue={trackingEventValue}
              hasScrollArrow={hasScrollArrow}
            />
          ) : null}
          <TextButton
            style="text"
            text={secondaryButtonText}
            linkUrl={secondaryButtonLink}
            color={secondaryCTATextColor}
          />
        </div>

        {digitalPaymentsText && (
          <div className="mt-20 flex flex-col items-center gap-12 sm:mt-40 sm:items-start lg:flex-row">
            <div className="flex min-w-max gap-12">
              <img
                src={"/icons/google-pay.svg"}
                alt="Google Pay"
                width={51}
                height={20}
                loading="lazy"
                className="h-[68px] w-[73px] rounded-12 border border-charcoal/20 px-[11px] py-24"
              />
              <img
                src={"/icons/apple-pay.svg"}
                alt="Google Pay"
                width={51}
                height={20}
                loading="lazy"
                className="h-[68px] w-[73px] rounded-12 border border-charcoal/20 px-[11px] py-24"
              />
            </div>

            <Typography
              font="grotesk"
              weight="book"
              size="body-md"
              color={accentColor}
              className="max-w-[344px] opacity-80"
              text={digitalPaymentsText}
            />
          </div>
        )}

        <StickyContainer
          isVisible={
            typeof isCtaVisible === "boolean" &&
            !isCtaVisible &&
            !navigationContext.isMobileMenuOpen
          }
        >
          {showDownloadAppButton ? (
            <DownloadAppButton
              text={primaryButtonText || "Download app"}
              color={primaryButtonBorderColor}
            />
          ) : primaryButtonText && primaryButtonLink ? (
            <PillButton
              size="large"
              style="solid"
              text={primaryButtonText}
              color={primaryButtonBorderColor}
              linkUrl={primaryButtonLink}
              mobileLinkUrl={primaryButtonMobileLink}
              trackingEvent={trackingEvent}
              trackingEventKey={trackingEventKey}
              trackingEventValue={trackingEventValue}
            />
          ) : null}
        </StickyContainer>
      </Section>
      <div className={classNames("sm:py-80 md:py-96", { "pb-40": videoUrl })}>
        {videoUrl ? (
          <Video
            video={videoUrl}
            loadType="eager"
            aspectRatio="1:1"
            playOnMobile={true}
            coverImage={imageUrl}
            playbackMode="autoplay"
            className="flex items-center"
            ImageAlt={imageAlt || "Hero image"}
          />
        ) : (
          <GatsbyStoryblokImage
            aspectRatio="1:1"
            loadType="eager"
            image={imageUrl}
            alt={imageAlt || "Hero image"}
            quality={80}
          />
        )}
      </div>
    </div>
  )
}

export default HalfHero
